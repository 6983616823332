.gridLeft {
    margin-top: 10px !important;
    text-align: right;
    padding-right: 5px;
  }

.gridRight {
    text-align: left;
    padding-left: 5px;
    margin-top: 10px !important;
}