.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
}

.Icon {
  color: white;
  height: 40px;
  width: 40px !important;
}
.Icon:hover {
  margin-top: -10px !important;
  color: orange;
  border-radius: 100%;
}

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}


.socialIcons {
  margin-top: 2vh;
}

.Footer {
  position: fixed;
  color: gray;
  font-size: 0.5em;
  bottom: 1%;
  width: 100%;
}
a {
  color: gray;
  text-decoration: unset;
}
a:hover {
  color: white;
}